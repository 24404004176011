import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { CiBarcode } from "react-icons/ci";
import { SaveAndCloseIcon,SaveIcon, PrioBack,} from "../svg/svgIcons";
import { useFormik } from 'formik'; 
import UtilContext from '../../components/UtilContext';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import AuthContext from '../../components/AuthContext';
import Quagga from 'quagga';

export default function OTKMeasure(){

    const navigate = useNavigate();

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);

    const [showDefault,setShowDefault] = useState(true);
    const [showDoc,setShowDoc] = useState(false);
    const [showErrors,setShowErrors] = useState(false);
    const [showProtocols,setShowProtocols] = useState(false);
    const [formType,setFormType] = useState("");
    const [showCam,setShowCam] = useState("")
    const [fileList, setFileList] = useState([]);
    const [saveAfterClose,setSaveAfterClose] = useState(false);
    const [showBarScanner, setShowBarScanner] = useState(false);

    const [scanValue,setScanValue] = useState("");
    const [scanTitle,setScanTitle] = useState("");

    const [offenderDepartment, setOffenderDepartment] = useState();
    const [offenders, setOffenders] = useState([]);
    const [recordProcessorDepartment, setRecordProcessorDepartment] = useState();
    const [recordProcessors, setRecordProcessors] = useState([]);


    const fileListRef = useRef(null);
    const submitRef = useRef(null);

    useEffect(() => {
        if(user?.employee?.id){
            filterRecordProcessors(user.employee.department_id);
            formik.setFieldValue('record_processor_id',user.employee.id);
        }
    },[user]);

    function documentationShow(){
        setShowDefault(true);
        setShowDoc(true);
        setShowErrors(false);
        setShowProtocols(false);
    }

    function errorsShow(){
        setShowDefault(true);
        setShowDoc(false);
        setShowErrors(true);
        setShowProtocols(false);
    }

    function protocolShow(){
        setShowDefault(true);
        setShowDoc(false);
        setShowErrors(false);
        setShowProtocols(true);
    }

    function defaultShow(){
        setShowDefault(true);
        setShowDoc(false);
        setShowErrors(false);
        setShowProtocols(false);
    }

    function barScanner(scanType,title){
        setShowBarScanner(true);
        setShowDefault(false);
        setShowDoc(false);
        setShowErrors(false);
        setShowProtocols(false);
        setScanValue(scanType);
        setScanTitle(title);
      }

      const stopScanner = () => {
        Quagga.offProcessed();
        Quagga.offDetected();
        Quagga.stop();
    };
    
    function handleFormType(value){
        formik.setFieldValue("type",value);
        if(value == "DOKUMENTÁCIA"){
            documentationShow();
        }
        else if(value == "SEBAKONTROLA" || value == "MERACÍ PROTOKOL" || value == "ŽÍHACÍ PROTOKOL"){
            protocolShow();
        }
        else if(value == "Interná chyba" || value == "Externá chyba" || value == "Reklamácia"){
            errorsShow();
        }
        else{
            defaultShow();
        }
    }

    const validate = values => {
        const errors = {};
        if (!values.type) {
            errors.type = '*Povinné';
        }
        return errors;
    };

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };


    const formik = useFormik({
        initialValues: {
            article_number: '',order_number:'',record_processor_id:'',type:'',
            total_amount:'1',measure_complexity_id:'',article_name:'',customer_id:'',
            offender_id:'',repair_costs:'25',ack_error:'',error_type_id:'',defective_pieces:'',
            group_id:'',problem_description:'',state:'Otvorená',
        },
        enableReinitialize: true,
        validate,
        onSubmit: values => {
          setDialogText("Ukladám");
          setVisible(true);
          let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            formData.append("contractor_id", user?.employee?.id ? user?.employee?.id : values.record_processor_id )
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            if(values.type=="Interná chyba" || values.type=="Externá chyba" || values.type=="Reklamácia"){
                postRequest('/api/error_records', formData,true)
                .then((response) => {
                    console.log(response);
                    for (let value in values) {
                        if(value!="record_processor_id" && value!="article_number" && value!="order_number"){
                            if(value=="number_of_pieces")formik.setFieldValue(value,'1');
                            else if(value=="repair_costs")formik.setFieldValue(value,'25');
                            else formik.setFieldValue(value,"");
                        }
                    }
                    defaultShow();
                    setFileList([]);
                    fileListRef.current.value = null;
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    if(saveAfterClose)navigate("/otkhome");
                    setVisible(false);
                }).catch((reason) => {
                    alert("Daný úkon sa nepodaril!");
                    setVisible(false);
                });
            }
            else{
                postRequest('/api/measures', formData,true)
                .then((response) => {
                    console.log(response);
                    setFileList([]);
                    for (let value in values) {
                        if(value!="record_processor_id" && value!="article_number" && value!="order_number"){
                            if(value=="number_of_pieces")formik.setFieldValue(value,'1');
                            else if(value=="repair_costs")formik.setFieldValue(value,'25');
                            else formik.setFieldValue(value,"");
                        }
                    }
                    defaultShow();
                    fileListRef.current.value = null;
                    toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    if(saveAfterClose)navigate("/otkhome");
                    setVisible(false);
                }).catch((reason) => {
                    alert("Daný úkon sa nepodaril!");
                    setVisible(false);
                })
            }
        },
    });

    useEffect(() => {
        if(scanValue!=""){
        const _onDetected = res => {
            stopScanner();
            if(scanValue=="article_number"){
                formik.setFieldValue("article_number",res.codeResult.code);
            }
            else if(scanValue=="order_number"){
                formik.setFieldValue("order_number",res.codeResult.code);
            }
            setScanValue("");
            setShowBarScanner(false);
            setShowDefault(true);
  
        };
        /* start camera */
        Quagga.init(
        {
            inputStream: {
            type: "LiveStream",
            constraints: {
                facingMode: "environment", // or user
            },
            },
            locator: {
            patchSize: "medium",
            halfSample: false,
            },
            numOfWorkers: 2,
            frequency: 10,
            decoder: {
            readers: ["code_128_reader"],
            debug: {
                drawBoundingBox: false,
                showFrequency: false,
                drawScanline: false,
                showPattern: false,
            },
            multiple: false,
            },
            locate: true,
        },
        function (err) {
            if (err) {
            return console.log(err);
            }
            Quagga.start();
        }
        );
        Quagga.onDetected(_onDetected);
        }
      }, [scanValue,formik]);

          /* filter offender by department in form */
    function filterOffenders(department){
        setOffenderDepartment(department);
        if(department === "Zákazník"){
            setOffenders(state.offenders
            .filter(offender => offender.type === 'customer')
            .map(offender => offender.customer));
        }
        else if(department === "Dodávateľ"){
            setOffenders(state.offenders
                .filter(offender => offender.type === 'supplier')
                .map(offender => offender.supplier));
        }
        else if(department === ""){
            setOffenders([]);
        }
        else{
            setOffenders(state.offenders
                .filter(offender => offender.type === 'employee')
                .filter(offender => offender.employee?.department_id === parseInt(department))
                .map(offender => offender.employee));
        }
    }

    /* filter record processors by department in form */
    function filterRecordProcessors(departmentId){
        setRecordProcessorDepartment(departmentId);
        setRecordProcessors(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    return(
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col pb-2 bg-white min-h-[calc(100vh_-_56px)]'>
        {showDefault && <><div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-evenly border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/otkhome")}><PrioBack/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setSaveAfterClose(true);submitRef.current.click()}}><SaveAndCloseIcon/></button></div>
                <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        </div></>}
            <div className='w-auto px-4 mx-2'>
        {showDefault && <><h1 className='w-full text-2xl font-bold mb-4 text-red-900'>NOVÉ MERANIE</h1>
                <div className='w-full mb-2'>
                    <label htmlFor='article_number' className='font-semibold '>
                        Artikel:
                        <input 
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='article_number'
                        onChange={formik.handleChange} value={formik.values.article_number}
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("article_number","artikla")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='order_number' className='font-semibold '>
                        Zákazka:
                        <input required
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='order_number'
                        onChange={formik.handleChange} value={formik.values.order_number}
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("order_number","zákazky")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='recordProcessorDepartment' className='font-semibold '>
                        Oddelenie zadávateľa:
                        <select className='w-full h-10 border border-black rounded-md text-black'
                        name="recordProcessorDepartment"
                        onChange={(e) => filterRecordProcessors(e.target.value)} value={recordProcessorDepartment}
                        >
                            <option value="">Zvoľ oddelenie</option>
                            {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='record_processor_id' className='font-semibold '>
                        Meno zadávateľa:
                        <select required
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="record_processor_id"
                        onChange={formik.handleChange} value={formik.values.record_processor_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {recordProcessors.map((recordProcessor) => (
                            <option key={recordProcessor.id} value={recordProcessor.id}>{recordProcessor.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='type' className='font-semibold '>
                        VLOŽ DO:
                        <select required value={formik.values.type} onChange={(e) => handleFormType(e.target.value)} className='w-full h-10 border border-black rounded-md text-black'
                        name="type">
                            <option value="">Vyber možnosť</option>
                            <option value="DOKUMENTÁCIA">Dokumentácia</option>
                            <option value="SEBAKONTROLA">Sebakontrola</option>
                            <option value="MERACÍ PROTOKOL">Merací protokol</option>
                            <option value="ŽÍHACÍ PROTOKOL">Žihací protokol</option>
                            <option value="Interná chyba">Interná chyba</option>
                            <option value="Externá chyba">Externá chyba</option>
                            <option value="Reklamácia">Reklamácia</option>
                        </select>
                    </label>
                </div></>}
                {showDoc && <><div className='w-full mb-2'>
                    <label htmlFor='total_amount' className='font-semibold'>
                        Počet meraných kusov:
                        <input required
                        className='w-full p-2 border border-black rounded-md'
                        type='number'
                        name='total_amount'
                        onChange={formik.handleChange} value={formik.values.total_amount}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='measure_complexity_id' className='font-semibold'>
                        Náročnosť dielu:
                        <select className='w-full h-10 border border-black rounded-md'
                        name="measure_complexity_id" required
                        onChange={formik.handleChange} value={formik.values.measure_complexity_id}
                        >
                            <option value="">Zvoľ náročnosť</option>
                            {state.measure_complexities.map((measure_complexity) => (
                            <option key={measure_complexity.id} value={measure_complexity.id}>{measure_complexity.category + " - " + measure_complexity.description}</option>
                          ))}
                        </select>
                    </label>
                </div></>}
                {showErrors && <>
                <div className='w-full mb-2'>
                    <label htmlFor='article_name' className='font-semibold'>
                        Názov artikla:
                        <input 
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='article_name'
                        onChange={formik.handleChange} value={formik.values.article_name}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='customer_id' className='font-semibold'>
                        Zákazník:
                        <select className='w-full h-10 border border-black rounded-md'
                        name="customer_id"
                        onChange={formik.handleChange} value={formik.values.customer_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {state.customers.map((customer) => (
                            <option key={customer.id} value={customer.id}>{customer.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='offenderDepartment' className='font-semibold'>
                        Oddelenie vinníka:
                        <select className='w-full h-10 border border-black rounded-md'
                        required
                        name="offenderDepartment"
                        onChange={(e) => filterOffenders(e.target.value)} value={offenderDepartment}
                        >
                            <option value="">Zvoľ oddelenie</option>
                            {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                          <option value="Zákazník">Zákazník</option>
                          <option value="Dodávateľ">Dodávateľ</option>
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='offender_id' className='font-semibold'>
                        Meno vinníka:
                        <select className='w-full h-10 border border-black rounded-md'
                        required
                        name="offender_id"
                        onChange={formik.handleChange} value={formik.values.offender_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {offenders.map((offender) => (
                            <option key={offender.offender_id} value={offender.offender_id}>{offender.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='repair_costs' className='font-semibold'>
                        Náklady:
                        <input 
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='repair_costs'
                        onChange={formik.handleChange} value={formik.values.repair_costs}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='total_amount' className='font-semibold'>
                        Množstvo:
                        <input 
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        required
                        name='total_amount'
                        onChange={formik.handleChange} value={formik.values.total_amount}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='error_type_id' className='font-semibold'>
                        Druh chyby:
                        <select className='w-full h-10 border border-black rounded-md'
                        required
                        name="error_type_id"
                        onChange={formik.handleChange} value={formik.values.error_type_id}
                        >
                            <option value="">Zvoľ chybu</option>
                            {state.error_types.map((error_type) => (
                            <option key={error_type.id} value={error_type.id}>{error_type.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='defective_pieces' className='font-semibold'>
                        Počet chybných:
                        <input 
                        className='w-full p-2 border border-black rounded-md'
                        required
                        type='text'
                        name='defective_pieces'
                        onChange={formik.handleChange} value={formik.values.defective_pieces}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='group_id' className='font-semibold'>
                        Skupina:
                        <select className='w-full h-10 border border-black rounded-md'
                        required
                        name="group_id"
                        onChange={formik.handleChange} value={formik.values.group_id}
                        >
                            <option value="">Zvoľ skupinu</option>
                            {state.groups.map((group) => (
                            <option key={group.id} value={group.id}>{group.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='problem_description' className='font-semibold'>
                        Popis problému:
                    <textarea name="problem_description" className='w-full p-2 border border-black rounded-md'
                    required
                    onChange={formik.handleChange} value={formik.values.problem_description}
                    >
                    </textarea>
                    </label>
                </div>
                </>}
                {(showProtocols || showErrors || showDoc) &&
                <div className='w-full mb-2'>
                    <label htmlFor='files' className='font-semibold'>
                        Prílohy:
                        <input 
                        accept='image/*,application/pdf'
                        className='w-full p-2 border border-black rounded-md'
                        type='file'
                        name='files'
                        multiple="multiple"
                        ref={fileListRef}
                        onChange={handleFileChange}
                        />
                    </label>
                </div>
                }
            </div>
            <div className={showBarScanner ? 'block bg-white h-[calc(100vh_-_56px)]' : 'hidden' }>
      <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-start border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
        <div className='flex flex-col w-20 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setShowDefault(true);setShowBarScanner(false);}}><span className='w-full flex flex-row'><PrioBack/><p className='text-red-950 font-semibold'>Späť</p></span></button></div>
      </div>
      <div>
        <div id="interactive" className="viewport">
          <h1 className='text-xl font-semibold text-center mb-2 text-zinc-900'>Skenovanie {scanTitle}</h1>
          <video className="max-w-full w-auto m-auto px-4 border-red-950 rounded-md" autoplay="true" preload="auto" src="" muted="true"
             playsinline="true"/>
          <canvas className="drawingBuffer hidden"></canvas>
        </div>
      </div>
      </div>
        </div>
        <button type="submit" ref={submitRef} className='hidden'></button>
        </form>
        </>
    )
}