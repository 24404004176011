import React, {useContext, useState, useRef, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { SaveAndCloseIcon,SaveIcon, PrioBack,} from "../svg/svgIcons";
import { CiBarcode } from "react-icons/ci";
import UtilContext from '../../components/UtilContext';
import Quagga from 'quagga';
import { useFormik } from 'formik'; 
import { getRequest, postRequest, deleteRequest } from '../../api/ApiController';
import { DateTime } from "luxon";
import { DatabaseContext } from '../../redux/DatabaseStore';
import { Dialog } from 'primereact/dialog';

export default function OTKSearch(){

    const {toast,visible,setDialogText,setVisible}= useContext(UtilContext);
    const [state,dispatch] = useContext(DatabaseContext);

    const navigate = useNavigate();

    const [dbDocumentation,setDbDocumentation] = useState(null);

    const [showDefault,setShowDefault] = useState(true);
    const [showDocTable,setShowDocTable] = useState(false);
    const [showErrorTable,setShowErrorTable] = useState(false);
    const [showDocumentation,setShowDocumentation] = useState(false);
    const [showErrorRecord,setShowErrorRecord] = useState(false);
    const [showDoc,setShowDoc] = useState(false);
    const [showBarScanner, setShowBarScanner] = useState(false);

    const [record,setRecord] = useState([]);

    const [scanValue,setScanValue] = useState("");
    const [scanTitle,setScanTitle] = useState("");

    const [offenderDepartment, setOffenderDepartment] = useState();
    const [offenders, setOffenders] = useState([]);
    const [recordProcessorDepartment, setRecordProcessorDepartment] = useState();
    const [recordProcessors, setRecordProcessors] = useState([]);
    const [contractorDepartment, setContractorDepartment] = useState();
    const [contractors,setContractors] = useState([]);

    const [images,setImages] = useState('');

    useEffect(() => {
        if(record!=null){
            if(formik.values.type!="Interná chyba" && formik.values.type!="Externá chyba" && formik.values.type!="Reklamácia"){
                getDocImages(record);
            }
            else{
                getErrorImages(record);
            }
        }
    },[record]);

    function getDocImages(record){
        getRequest(`/api/measures/images/${record.id}`).then((response) => {
            if(response.data!=="{}")setImages(response.data);
        });
    }

    function getErrorImages(record){
        getRequest(`/api/error_records/images/${record.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    function barScanner(scanType,title){
        setShowBarScanner(true);
        setShowDefault(false);
        setScanValue(scanType);
        setScanTitle(title);
      }

    function docTable(){
        setShowDocTable(true);
        setShowDefault(true);
        setShowErrorTable(false);
    }

    function errorTable(){
        setShowDocTable(false);
        setShowDefault(true);
        setShowErrorTable(true);
    }

    function errorRecord(){
        setShowDefault(false);
        setShowErrorRecord(true);
        setShowDocumentation(false);
    }
    function documentation(){
        setShowDefault(false);
        setShowErrorRecord(false);
        setShowDocumentation(true);
    }

    function defaultPage(){
        setShowDefault(true);
        setShowErrorRecord(false);
        setShowDocumentation(false);
    }

      const stopScanner = () => {
        Quagga.offProcessed();
        Quagga.offDetected();
        Quagga.stop();
    };

      const formik = useFormik({
        initialValues: {
            order_number:'',type:'',
        },
        enableReinitialize:true,
        onSubmit: values => {
            setDialogText("Načítavam");
            setVisible(true);
            let formData = new FormData();
            formData.append("order_number",values.order_number);
            formData.append("type",values.type);
            let url = "";
            if(values.type=="Dokumentácia"){
                url = '/api/measure/getDocumentation';
            }
            else if(values.type=="Sebakontrola"){
                url = '/api/measure/getSelfControl';
            }
            else if(values.type=="Merací protokol"){
                url = '/api/measure/getMeasurementProtocol';
            }
            else if(values.type=="NDT protokol"){
                url = '/api/measure/getNDTProtocol';
            }
            else if(values.type=="Žihací protokol"){
                url = '/api/measure/getAnnealingProtocol';
            }
            else{
                url = '/api/error_records/getByOrderNumber';
            }
            postRequest(url, formData,true)
            .then((response) => {
                setDbDocumentation(response.data);
                if(values.type!="Interná chyba" && values.type!="Externá chyba" && values.type!="Reklamácia"){
                    docTable();
                }
                else{
                    errorTable();
                }
                setVisible(false);
            }).catch((reason) => {
                alert("Nepodarilo sa načítať údaje!");
                setVisible(false);
            });
        },
    });

    useEffect(() => {
        if(scanValue!=""){
        const _onDetected = res => {
            stopScanner();
            if(scanValue=="order_number"){
                formik.setFieldValue("order_number",res.codeResult.code);
            }
            setScanValue("");
            setShowBarScanner(false);
            setShowDefault(true);
  
        };
        /* start camera */
        Quagga.init(
        {
            inputStream: {
            type: "LiveStream",
            constraints: {
                facingMode: "environment", // or user
            },
            },
            locator: {
            patchSize: "medium",
            halfSample: false,
            },
            numOfWorkers: 2,
            frequency: 10,
            decoder: {
            readers: ["code_128_reader"],
            debug: {
                drawBoundingBox: false,
                showFrequency: false,
                drawScanline: false,
                showPattern: false,
            },
            multiple: false,
            },
            locate: true,
        },
        function (err) {
            if (err) {
            return console.log(err);
            }
            Quagga.start();
        }
        );
        Quagga.onDetected(_onDetected);
        }
      }, [scanValue, formik]);

      function filterOffenders(department){
        setOffenderDepartment(department);
        if(department === "Zákazník"){
            setOffenders(state.offenders
            .filter(offender => offender.type === 'customer')
            .map(offender => offender.customer));
        }
        else if(department === "Dodávateľ"){
            setOffenders(state.offenders
                .filter(offender => offender.type === 'supplier')
                .map(offender => offender.supplier));
        }
        else if(department === ""){
            setOffenders([]);
        }
        else{
            setOffenders(state.offenders
                .filter(offender => offender.type === 'employee')
                .filter(offender => offender.employee?.department_id === parseInt(department))
                .map(offender => offender.employee));
        }
    }

    /* filter record processors by department in form */
    function filterRecordProcessors(departmentId){
        setRecordProcessorDepartment(departmentId);
        setRecordProcessors(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    function filterContractors(departmentId){
        setContractorDepartment(departmentId);
        setContractors(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    function setDepartmentsWithEmployees(record){
        if(record.offender_id){
            const offender = state.offenders.filter((offender) => {
                if(offender.employee && offender.employee.offender_id === record.offender_id){
                    let edit_offender = offender;
                    edit_offender.department = offender.employee.department_id;
                    return edit_offender;
                }
                if(offender.supplier && offender.supplier.offender_id === record.offender_id){
                    let edit_offender = offender;
                    edit_offender.department = "Dodávateľ";
                    return edit_offender;
                }
                if(offender.customer && offender.customer.offender_id === record.offender_id){
                    let edit_offender = offender;
                    edit_offender.department = "Zákazník";
                    return edit_offender;
                }
                return false;
            });
            if(offender.length>0)filterOffenders(offender[0].department);
        }
        if(record.record_processor_id){
            const record_processor = state.employees.filter(record_processor => record_processor.id === record.record_processor_id);
            filterRecordProcessors(record_processor[0].department.id);
        }
        /*if(record.auditor_id){
            const auditor = state.employees.filter(auditor => auditor.id === record.auditor_id);
            filterAuditors(auditor[0].department.id);
        }*/
}

    return(
        <>
        <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col bg-white h-[calc(100vh_-_56px)] pb-2'>
        {showDefault && <><div className='sticky top-14 left-0 w-full h-10 flex flex-row border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-row w-10 h-10 px-2 py-1 justify-left'><button className="disabled:opacity-25"  type='button' onClick={() => navigate("/otkhome")}><PrioBack/></button></div>
        </div>
            <div className='w-auto px-4 mx-2'>
            <h1 className='w-full text-2xl font-bold mb-4 text-red-900'>VYHĽADÁVANIE</h1>
                <div className='w-full mb-2'>
                    <label htmlFor='type' className='font-semibold'>
                        Vyhľadať z:
                        <select className='w-full h-10 border border-black rounded-md'
                        name="type"
                        required
                        onChange={formik.handleChange} value={formik.values.type}
                        >
                            <option value="" >Zvoľ typ</option>
                            <option value="Dokumentácia" >Dokumentácia</option>
                            <option value="Sebakontrola" >Sebakontrola</option>
                            <option value="Merací protokol" >Merací protokol</option>
                            <option value="NDT protokol" >NDT protokol</option>
                            <option value="Žihací protokol" >Žihací protokol</option>
                            <option value="Interná chyba" >Interná chyba</option>
                            <option value="Externá chyba" >Externá chyba</option>
                            <option value="Reklamácia" >Reklamácia</option>
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='order_number' className='font-semibold '>
                        Zákazka:
                        <input 
                        required
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='order_number'
                        onChange={formik.handleChange} value={formik.values.order_number}
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("order_number","zákazky")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                    </label>
                </div>
                    <button type="submit" className='bg-red-950 w-full p-2 text-white font-semibold rounded-md hover:bg-gray-800'>HĽADAŤ</button>
                </div>
                {showDocTable && <><div className='flex flex-col w-auto px-4 mx-2 mt-2'>
                    {dbDocumentation?.map((item,index) => {
                        return <div className='flex flex-row'>
                    <div className=''>
                        <label className='font-semibold '>Zamestnanec:</label>
                        <input
                        value={item.contractor?.name}
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='employee'  
                        />
                    </div>
                    <div className='ml-2'>
                        <label className='font-semibold '>Dátum:</label>
                        <input
                        readOnly
                        value={DateTime.fromISO(item.created_at).toFormat("dd.MM.yy HH:mm").toString()}
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='created_at'  
                        />
                    </div>
                    <div className=''>
                        <label className='font-semibold '>Záznam:</label>
                        <div className='flex justify-center'><button onClick={() => {setRecord(item);filterContractors(item.contractor.department_id);documentation();}} type="button" className='py-3'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#009BCE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg></button></div>
                    </div></div>})}
                </div></>}
                {showErrorTable && <>
                    <div className='flex flex-col w-auto px-4 mx-2 mt-2'>
                    {dbDocumentation?.map((item,index) => {
                        return <div className='flex flex-row'>
                        <div className=''>
                        <label className='font-semibold '>Por. číslo:</label>
                        <input
                        value={item.specific_id}
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='employee'  
                        />
                    </div>
                    <div className='ml-2'>
                        <label className='font-semibold '>Dátum:</label>
                        <input
                        readOnly
                        value={DateTime.fromISO(item.created_at).toFormat("dd.MM.yy HH:mm").toString()}
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='created_at'  
                        />
                    </div>
                    <div className=''>
                        <label className='font-semibold '>Záznam:</label>
                        <div className='flex justify-center'><button onClick={() => {setRecord(item);setDepartmentsWithEmployees(item);errorRecord();}} type="button" className='py-3'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#009BCE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg></button></div>
                    </div></div>})}
                    </div>
                </>}
                </>}
                <div className={showBarScanner ? 'block bg-white h-[calc(100vh_-_56px)]' : 'hidden' }>
      <div className='sticky top-14 left-0 w-full h-10 flex flex-row justify-start border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
        <div className='flex flex-col w-20 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25"  type='button' onClick={() => {setShowDefault(true);setShowBarScanner(false);}}><span className='w-full flex flex-row'><PrioBack/><p className='text-red-950 font-semibold'>Späť</p></span></button></div>
      </div>
      <div>
        <div id="interactive" className="viewport">
          <h1 className='text-xl font-semibold text-center mb-2 text-zinc-900'>Skenovanie {scanTitle}</h1>
          <video className="max-w-full w-auto m-auto px-4 border-red-950 rounded-md" autoplay="true" preload="auto" src="" muted="true"
             playsinline="true"/>
          <canvas className="drawingBuffer hidden"></canvas>
        </div>
      </div>
      </div>
        <div className='flex flex-col pb-2 bg-white'>
            {showErrorRecord && <>
            <div className='sticky top-14 left-0 w-full h-10 flex flex-row border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-row w-10 h-10 px-2 py-1 justify-left'><button className="disabled:opacity-25"  type='button' onClick={() => defaultPage()}><PrioBack/></button></div>
            </div>
            <div className='w-auto px-4 mx-2'>
            <div className='w-full mb-2'>
                    <h1 className='font-semibold text-2xl text-center'>{formik.values.type}</h1>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='article_number' className='font-semibold '>
                        Artikel:
                        <input 
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='article_number'
                        value={record.article_number}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='order_number' className='font-semibold '>
                        Zákazka:
                        <input
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='order_number'
                        value={record.order_number}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='recordProcessorDepartment' className='font-semibold '>
                        Oddelenie zadávateľa:
                        <select className='w-full h-10 border border-black rounded-md text-black'
                        name="recordProcessorDepartment"
                        disabled
                        value={recordProcessorDepartment}
                        >
                            <option value="">Zvoľ oddelenie</option>
                            {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='record_processor_id' className='font-semibold '>
                        Meno zadávateľa:
                        <select 
                        disabled
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="record_processor_id"
                        value={record.record_processor_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {recordProcessors.map((recordProcessor) => (
                            <option key={recordProcessor.id} value={recordProcessor.id}>{recordProcessor.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='article_name' className='font-semibold'>
                        Názov artikla:
                        <input 
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='article_name'
                        value={record.article_name}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='customer_id' className='font-semibold'>
                        Zákazník:
                        <select className='w-full h-10 border border-black rounded-md'
                        disabled
                        name="customer_id"
                        value={record.customer_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {state.customers.map((customer) => (
                            <option key={customer.id} value={customer.id}>{customer.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='offenderDepartment' className='font-semibold'>
                        Oddelenie vinníka:
                        <select className='w-full h-10 border border-black rounded-md'
                        disabled
                        name="offenderDepartment"
                        value={offenderDepartment}
                        >
                            <option value="">Zvoľ oddelenie</option>
                            {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                          <option value="Zákazník">Zákazník</option>
                          <option value="Dodávateľ">Dodávateľ</option>
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='offender_id' className='font-semibold'>
                        Meno vinníka:
                        <select className='w-full h-10 border border-black rounded-md'
                        disabled
                        name="offender_id"
                        value={record.offender_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {offenders.map((offender) => (
                            <option key={offender.offender_id} value={offender.offender_id}>{offender.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='repair_costs' className='font-semibold'>
                        Náklady:
                        <input 
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='repair_costs'
                        value={record.repair_costs}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='total_amount' className='font-semibold'>
                        Množstvo:
                        <input 
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        required
                        name='total_amount'
                        value={record.total_amount}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='error_type_id' className='font-semibold'>
                        Druh chyby:
                        <select className='w-full h-10 border border-black rounded-md'
                        disabled
                        name="error_type_id"
                        value={record.error_type_id}
                        >
                            <option value="">Zvoľ chybu</option>
                            {state.error_types.map((error_type) => (
                            <option key={error_type.id} value={error_type.id}>{error_type.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='defective_pieces' className='font-semibold'>
                        Počet chybných:
                        <input 
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='defective_pieces'
                        value={record.defective_pieces}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='group_id' className='font-semibold'>
                        Skupina:
                        <select className='w-full h-10 border border-black rounded-md'
                        name="group_id"
                        value={record.group_id}
                        disabled
                        >
                            <option value="">Zvoľ skupinu</option>
                            {state.groups.map((group) => (
                            <option key={group.id} value={group.id}>{group.name}</option>
                          ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='problem_description' className='font-semibold'>
                        Popis problému:
                    <textarea name="problem_description" className='w-full p-2 border border-black rounded-md'
                    readOnly
                    value={record.problem_description}
                    >
                    </textarea>
                    </label>
                </div>
                <div>
                    <label
                        htmlFor="images"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Galéria:
                    </label>
                    {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                        <tr>
                            <td className='text-black h-10'><div className='flex flex-row p-2'>
                            <div>
                                    <a href={img.url}>
                                    <iframe src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></iframe>
                                    </a>
                                </div>
                                    <div className='flex flex-col ml-4'>
                                    <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} href={img.url}>{img.filename.substring(0, 11) + "..."}</a>
                                    </div>
                                </div></td>
                        </tr>
                    </table>))}
                </div>
                </div></>}
                {showDocumentation && <>
            <div className='sticky top-14 left-0 w-full h-10 flex flex-row border bg-white focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                <div className='flex flex-row w-10 h-10 px-2 py-1 justify-left'><button className="disabled:opacity-25"  type='button' onClick={() => defaultPage()}><PrioBack/></button></div>
            </div>
            <div className='w-auto px-4 mx-2'>
            <div className='w-full mb-2'>
                    <h1 className='font-semibold text-2xl text-center'>{formik.values.type}</h1>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='article_number' className='font-semibold '>
                        Artikel:
                        <input 
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='article_number'
                        value={record.article_number}
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("article_number","artikla")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='order_number' className='font-semibold '>
                        Zákazka:
                        <input
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='text'
                        name='order_number'
                        value={record.order_number}
                        />
                        <div className='flex flex-row items-center'>
                        <button type='button' onClick={() => barScanner("order_number","zákazky")}>
                            <CiBarcode className='w-8 h-8 mr-2'/>
                            </button>
                        </div>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='contractorDepartment' className='font-semibold '>
                        Oddelenie zadávateľa:
                        <select className='w-full h-10 border border-black rounded-md text-black'
                        name="contractorDepartment"
                        value={contractorDepartment}
                        disabled
                        >
                            <option value="">Zvoľ oddelenie</option>
                            {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='contractor_id' className='font-semibold '>
                        Meno zadávateľa:
                        <select
                        disabled
                        className='w-full h-10 border border-black rounded-md text-black'
                        name="contractor_id"
                        value={record.contractor_id}
                        >
                            <option value="">Zvoľ meno</option>
                            {contractors.map((contractor) => (
                            <option key={contractor.id} value={contractor.id}>{contractor.name}</option>
                            ))}
                        </select>
                    </label>
                </div>
                    {formik.values.type == "Dokumentácia" && <><div className='w-full mb-2'>
                    <label htmlFor='total_amount' className='font-semibold'>
                        Počet meraných kusov:
                        <input
                        readOnly
                        className='w-full p-2 border border-black rounded-md'
                        type='number'
                        name='total_amount'
                        value={record.total_amount}
                        />
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='measure_complexity_id' className='font-semibold'>
                        Náročnosť dielu:
                        <select className='w-full h-10 border border-black rounded-md'
                        name="measure_complexity_id"
                        disabled
                        value={record.measure_complexity_id}
                        >
                            <option value="">Zvoľ náročnosť</option>
                            {state.measure_complexities.map((measure_complexity) => (
                            <option key={measure_complexity.id} value={measure_complexity.id}>{measure_complexity.category + " - " + measure_complexity.description}</option>
                          ))}
                        </select>
                    </label>
                </div> </>}
                <div>
                    <label
                        htmlFor="images"
                        className="block text-md font-semibold"
                    >
                        Galéria:
                    </label>
                    {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                        <tr>
                            <td className='text-black h-10'><div className='flex flex-row p-2'>
                                <div>
                                    <a href={img.url}>
                                    <iframe src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></iframe>
                                    </a>
                                </div>
                                    <div className='flex flex-col ml-4'>
                                    <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} href={img.url}>{img.filename.substring(0, 11) + "..."}</a>
                                    </div>
                                </div></td>
                        </tr>
                    </table>))}
                </div>
                </div></>}
            </div>
            </div>
        </form>
        </>
    )
}